import React from "react";
import DataManager from "./DataManager";
import { useOutletContext } from "react-router-dom";

export function TycoonPage() {
  const { isAdmin } = useOutletContext();

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-200">
          Tycoon Requires
        </h2>
        <p className="mt-2 text-sm text-gray-400">
          Manage and organize tycoon purchases
        </p>
      </div>
      <div className="bg-[#18181B] rounded-xl shadow-xl shadow-black/20 p-6">
        <DataManager isAdmin={isAdmin} />
      </div>
    </div>
  );
}
