import React, { useState, useEffect } from "react";
import { getDatabase, ref, get, set, onValue } from "firebase/database";
import { auth } from "../../firebase";
import JsonModal from "./JsonViewer";
import BulkAddModal from "./BulkAddModal";
import DuplicateModal from "./DuplicateModal";
import VirtualList from "./VirtualList";

const DataManager = ({ isAdmin }) => {
  const [currentProject, setCurrentProject] = useState("");
  const [projects, setProjects] = useState({});
  const [newItemId, setNewItemId] = useState("");
  const [newItemName, setNewItemName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [duplicates, setDuplicates] = useState([]);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showJsonModal, setShowJsonModal] = useState(false);
  const [showBulkAddModal, setShowBulkAddModal] = useState(false);
  const [error, setError] = useState("");
  const [debugLog, setDebugLog] = useState([]);
  const [inputCount, setInputCount] = useState(0);
  const [lastReset, setLastReset] = useState(null);

  const addToDebugLog = (message, data = null) => {
    const timestamp = new Date().toISOString();
    const logEntry = {
      timestamp,
      message,
      data: data ? JSON.stringify(data, null, 2) : null,
    };
    setDebugLog((prevLog) => [...prevLog, logEntry]);
  };

  // Track daily inputs
  useEffect(() => {
    if (!auth.currentUser) return;

    const db = getDatabase();
    const userInputRef = ref(db, `userInputs/${auth.currentUser.uid}`);

    const checkDailyReset = async () => {
      const today = new Date().toISOString().split("T")[0];

      const snapshot = await get(userInputRef);
      const userData = snapshot.val() || { count: 0, lastReset: "" };

      if (userData.lastReset !== today) {
        // Reset count for new day
        await set(userInputRef, {
          count: 0,
          lastReset: today,
        });
        setInputCount(0);
        setLastReset(today);
      } else {
        setInputCount(userData.count || 0);
        setLastReset(userData.lastReset);
      }
    };

    checkDailyReset();

    // Listen for changes to input count
    const unsubscribe = onValue(userInputRef, (snapshot) => {
      const data = snapshot.val() || { count: 0 };
      setInputCount(data.count);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const dataRef = ref(db, "projects");

    // Test database connectivity
    const testConnection = async () => {
      try {
        const testRef = ref(db, ".info/connected");
        const snapshot = await get(testRef);
        addToDebugLog("Database connection test", {
          connected: snapshot.val(),
          database: db.app.options.databaseURL,
        });
      } catch (err) {
        addToDebugLog("Database connection test failed", {
          error: err.message,
        });
      }
    };

    testConnection();

    addToDebugLog("Initializing database connection", {
      path: dataRef.toString(),
      auth: !!auth.currentUser,
    });

    const unsubscribe = onValue(
      dataRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setProjects(data);
        } else if (!snapshot.exists()) {
          const defaultData = {
            "Mining Tycoon": {},
          };
          set(dataRef, defaultData)
            .then(() => {
              setProjects(defaultData);
              setCurrentProject("Mining Tycoon");
            })
            .catch((err) => {
              setError("Failed to initialize data: " + err.message);
              console.error(err);
            });
        }
      },
      (error) => {
        setError("Failed to load data: " + error.message);
        console.error(error);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (projects && Object.keys(projects).length > 0) {
      if (!currentProject || !projects[currentProject]) {
        setCurrentProject(Object.keys(projects)[0]);
      }
    }
  }, [projects, currentProject]);

  const saveData = async (newData) => {
    const db = getDatabase();
    const dataRef = ref(db, "projects");
    setDebugLog([]);

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("User not authenticated");
      }

      addToDebugLog("Authentication state", {
        isAuthenticated: true,
        userId: currentUser.uid,
      });

      if (!newData || Object.keys(newData).length === 0) {
        throw new Error("Cannot save empty data");
      }

      addToDebugLog("Save preparation", {
        currentState: projects,
        newState: newData,
      });

      // Write the data
      await set(dataRef, newData);
      addToDebugLog("Initial write completed");

      // Add a delay before verification
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Verify with multiple attempts
      let savedData = null;
      let attempts = 3;

      while (attempts > 0 && !savedData) {
        addToDebugLog(`Verification attempt ${4 - attempts}`);
        const snapshot = await get(dataRef);
        savedData = snapshot.val();

        if (!savedData && attempts > 1) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          attempts--;
          continue;
        }

        break;
      }

      if (!savedData) {
        addToDebugLog("Final verification check failed", {
          attempts: 3 - attempts,
          finalData: savedData,
        });
        throw new Error(
          "Data not persisted after multiple verification attempts"
        );
      }

      addToDebugLog("Verification successful", {
        finalData: savedData,
      });

      setProjects(savedData);
      return savedData;
    } catch (error) {
      const errorMessage = `Save operation failed: ${error.message}`;
      setError(errorMessage);
      addToDebugLog("Error in save operation", {
        error: error.message,
        stack: error.stack,
      });
      throw error;
    }
  };

  // Modified handleAddItem with input limiting
  const handleAddItem = async () => {
    if (!newItemName) return;

    if (!isAdmin && inputCount >= 500) {
      setError(
        "Daily input limit reached (500/500). Please try again tomorrow."
      );
      return;
    }

    if (newItemName.length > 50) {
      setError("Item name cannot exceed 100 characters");
      return;
    }

    const db = getDatabase();
    const userInputRef = ref(db, `userInputs/${auth.currentUser.uid}`);

    try {
      // Update input count for non-approved users
      if (isAdmin) {
        await set(userInputRef, {
          count: inputCount + 1,
          lastReset: lastReset,
        });
      }

      // Create a copy of the current project data
      let projectData = projects[currentProject]
        ? { ...projects[currentProject] }
        : {};

      // Remove placeholder if present
      if (
        Object.keys(projectData).length === 1 &&
        projectData["__PLACEHOLDER__"] === "__EMPTY__"
      ) {
        projectData = {};
      }

      let id = newItemId;
      if (!id) {
        const existingIds = Object.keys(projectData).filter(
          (key) => key !== "__PLACEHOLDER__"
        );
        id =
          existingIds.length > 0
            ? String(
                Math.max(...existingIds.map(Number).filter((n) => !isNaN(n))) +
                  1
              )
            : "0";
      }

      const updatedProjects = {
        ...projects,
        [currentProject]: {
          ...projectData,
          [id]: newItemName,
        },
      };

      await saveData(updatedProjects);
      setNewItemId("");
      setNewItemName("");
    } catch (err) {
      console.error("Failed to add item:", err);
      setError(err.message);
    }
  };

  const handleRemoveItem = (id) => {
    // Create a copy of the current project data
    const projectData = { ...projects[currentProject] } || {};

    // Remove the specific item
    delete projectData[id];

    // If no items remain, add a placeholder
    if (Object.keys(projectData).length === 0) {
      projectData["__PLACEHOLDER__"] = "__EMPTY__";
    }

    const updatedProjects = {
      ...projects,
      [currentProject]: projectData,
    };

    saveData(updatedProjects).catch((err) => {
      console.error("Failed to remove item:", err);
    });
  };

  const handleCreateProject = (projectName) => {
    if (!projectName) return;

    if (projects[projectName]) {
      setError("Project already exists");
      return;
    }

    const updatedProjects = {
      ...projects,
      [projectName]: {
        __PLACEHOLDER__: "__EMPTY__", // Add a placeholder to ensure the project exists
      },
    };

    saveData(updatedProjects)
      .then(() => {
        setCurrentProject(projectName);
      })
      .catch((err) => {
        console.error("Failed to create project:", err);
        setError(`Failed to create project: ${err.message}`);
      });
  };

  const handleNewProject = () => {
    const name = prompt("Enter new project name:");
    if (name) handleCreateProject(name);
  };

  const handleDeleteProject = () => {
    if (Object.keys(projects).length <= 1) {
      setError("Cannot delete the last project");
      return;
    }

    const { [currentProject]: deleted, ...remainingProjects } = projects;
    const nextProject = Object.keys(remainingProjects)[0];

    saveData(remainingProjects)
      .then(() => {
        setCurrentProject(nextProject);
      })
      .catch((err) => {
        console.error("Failed to delete project:", err);
      });
  };

  const checkDuplicates = (data) => {
    const nameCount = {};
    const duplicatesList = [];

    Object.entries(data || {}).forEach(([id, name]) => {
      if (name in nameCount) {
        nameCount[name].push(id);
        if (nameCount[name].length === 2) {
          duplicatesList.push([name, nameCount[name]]);
        }
      } else {
        nameCount[name] = [id];
      }
    });

    setDuplicates(duplicatesList);
    setShowDuplicateModal(true); // Always show the modal regardless of duplicates found
  };
  const handleKeyPress = (e) => {
    // Check if the pressed key is Enter
    if (e.key === "Enter") {
      handleAddItem();
    }
  };

  const searchTerms = searchTerm
    .split(",")
    .map((term) => term.trim().toLowerCase())
    .filter((term) => term.length > 0);

  const filteredItems =
    currentProject && projects[currentProject]
      ? Object.entries(projects[currentProject])
          .filter(([id]) => id !== "__PLACEHOLDER__")
          .filter(
            ([id, name]) =>
              searchTerms.length === 0 || // Show all items if no search terms
              searchTerms.some(
                (term) =>
                  id.toLowerCase().includes(term) ||
                  name.toLowerCase().includes(term)
              )
          )
      : [];

  // Modified handleBulkAdd with permissions
  const handleBulkAdd = async (input) => {
    if (!input || !isAdmin) {
      setError("Unauthorized: Bulk add is restricted");
      return;
    }

    const items = input
      .split("\n")
      .map((item) => item.trim())
      .filter((item) => item !== "" && item.length <= 50);

    if (items.length === 0) {
      setError("No valid items to add");
      return;
    }

    try {
      let projectData = projects[currentProject]
        ? { ...projects[currentProject] }
        : {};

      if (
        Object.keys(projectData).length === 1 &&
        projectData["__PLACEHOLDER__"] === "__EMPTY__"
      ) {
        projectData = {};
      }

      const existingIds = Object.keys(projectData).filter(
        (key) => key !== "__PLACEHOLDER__"
      );
      let nextId =
        existingIds.length > 0
          ? Math.max(...existingIds.map(Number).filter((n) => !isNaN(n))) + 1
          : 0;

      const newItems = {};
      items.forEach((item) => {
        if (item.length <= 100) {
          newItems[nextId.toString()] = item;
          nextId++;
        }
      });

      const updatedProjects = {
        ...projects,
        [currentProject]: {
          ...projectData,
          ...newItems,
          bulk_add: true, // Add flag for security rules
        },
      };

      await saveData(updatedProjects);

      // Remove bulk_add flag
      delete updatedProjects[currentProject].bulk_add;
      await saveData(updatedProjects);
    } catch (err) {
      console.error("Failed to bulk add items:", err);
      setError(`Failed to bulk add items: ${err.message}`);
    }
  };

  const handleEditItem = async (id, newName) => {
    if (!newName) return;

    if (!isAdmin && inputCount >= 500) {
      setError(
        "Daily input limit reached (500/500). Please try again tomorrow."
      );
      return;
    }

    if (newName.length > 50) {
      setError("Item name cannot exceed 50 characters");
      return;
    }

    const db = getDatabase();
    const userInputRef = ref(db, `userInputs/${auth.currentUser.uid}`);

    try {
      // Update input count for non-admin users
      if (!isAdmin) {
        await set(userInputRef, {
          count: inputCount + 1,
          lastReset: lastReset,
        });
      }

      // Create a copy of the current project data
      const projectData = { ...projects[currentProject] };

      // Update the item
      const updatedProjects = {
        ...projects,
        [currentProject]: {
          ...projectData,
          [id]: newName,
        },
      };

      await saveData(updatedProjects);
    } catch (err) {
      console.error("Failed to edit item:", err);
      setError(err.message);
    }
  };

  const handleReorderIds = async () => {
    if (!isAdmin) {
      setError("Unauthorized: Reordering is restricted to admins");
      return;
    }

    try {
      const projectData = { ...projects[currentProject] };

      // Filter out placeholder and create array of entries
      const entries = Object.entries(projectData)
        .filter(([key]) => key !== "__PLACEHOLDER__")
        .sort((a, b) => {
          // Try to sort numerically first
          const numA = parseInt(a[0]);
          const numB = parseInt(b[0]);
          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
          }
          // Fall back to string comparison
          return a[0].localeCompare(b[0]);
        });

      // Create new object with reordered IDs
      const reorderedData = {};
      entries.forEach(([_, value], index) => {
        reorderedData[index.toString()] = value;
      });

      // Update the projects data
      const updatedProjects = {
        ...projects,
        [currentProject]: reorderedData,
      };

      await saveData(updatedProjects);
      addToDebugLog("IDs reordered successfully", {
        projectName: currentProject,
        itemCount: entries.length,
      });
    } catch (err) {
      console.error("Failed to reorder IDs:", err);
      setError(`Failed to reorder IDs: ${err.message}`);
      addToDebugLog("Reorder IDs failed", {
        error: err.message,
        stack: err.stack,
      });
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto space-y-6">
      {/* Modals */}
      {showBulkAddModal && isAdmin && (
        <BulkAddModal
          onAdd={handleBulkAdd}
          onClose={() => setShowBulkAddModal(false)}
        />
      )}
      {showDuplicateModal && (
        <DuplicateModal
          duplicates={duplicates}
          onClose={() => setShowDuplicateModal(false)}
        />
      )}
      {showJsonModal && isAdmin && (
        <JsonModal
          data={projects[currentProject] || {}}
          onClose={() => setShowJsonModal(false)}
        />
      )}

      {/* Project Controls */}
      <div className="flex items-center gap-4">
        <select
          value={currentProject}
          onChange={(e) => setCurrentProject(e.target.value)}
          className="p-2 rounded bg-gray-800 text-white"
        >
          {Object.keys(projects).map((project) => (
            <option key={project} value={project}>
              {project}
            </option>
          ))}
        </select>
        <button
          onClick={handleNewProject}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          New Project
        </button>
        {isAdmin && (
          <>
            <button
              onClick={() => {
                if (Object.keys(projects).length <= 1) {
                  setError("Cannot delete the last project");
                  return;
                }
                if (
                  window.confirm(
                    `Are you sure you want to delete "${currentProject}"?`
                  )
                ) {
                  handleDeleteProject();
                }
              }}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              disabled={Object.keys(projects).length <= 1}
            >
              Delete Project
            </button>
            <button
              onClick={() => setShowBulkAddModal(true)}
              className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
            >
              Bulk Add
            </button>
            <button
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to reorder all IDs? This action cannot be undone."
                  )
                ) {
                  handleReorderIds();
                }
              }}
              className="px-4 py-2 bg-yellow-600 text-white rounded hover:bg-yellow-700"
            >
              Reorder IDs
            </button>
          </>
        )}
      </div>

      {/* Input count display for regular users */}
      {!isAdmin && (
        <div className="text-sm text-gray-400">
          Daily inputs: {inputCount}/500
        </div>
      )}

      {/* Error Display */}
      {error && (
        <div className="space-y-4">
          <div className="p-4 mb-4 text-red-500 bg-red-100 rounded">
            {error}
          </div>
          <div className="p-4 bg-gray-900 rounded overflow-x-auto">
            <h3 className="text-lg font-bold mb-2">Debug Log:</h3>
            {debugLog.map((entry, index) => (
              <div key={index} className="mb-2 font-mono text-sm">
                <div className="text-gray-400">{entry.timestamp}</div>
                <div className="text-white">{entry.message}</div>
                {entry.data && (
                  <pre className="text-gray-300 mt-1 overflow-x-auto">
                    {entry.data}
                  </pre>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Add Item Controls */}
      <div className="flex gap-4">
        <input
          type="text"
          value={newItemId}
          onChange={(e) => setNewItemId(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="ID (optional)"
          className="p-2 rounded bg-gray-800 text-white"
        />
        <input
          type="text"
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Name"
          className="p-2 rounded bg-gray-800 text-white flex-1"
          maxLength={50}
        />
        <button
          onClick={handleAddItem}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
        >
          Add
        </button>
      </div>

      {/* Search Input */}
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by ID or Name (comma-separated for multiple terms, e.g.: Chair, Dropper)"
        className="w-full p-2 rounded bg-gray-800 text-white"
      />

      {/* Virtual List */}
      <VirtualList
        items={filteredItems}
        onRemove={handleRemoveItem}
        onEdit={handleEditItem}
      />

      {/* Bottom Actions */}
      <div className="flex items-center gap-4">
        {isAdmin && (
          <button
            onClick={() => setShowJsonModal(true)}
            className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
          >
            View JSON
          </button>
        )}
        <button
          onClick={() => checkDuplicates(projects[currentProject])}
          className="px-4 py-2 bg-yellow-600 text-white rounded hover:bg-yellow-700"
        >
          Check Duplicates
        </button>
        <span className="text-white">Duplicates: {duplicates.length}</span>
      </div>
    </div>
  );
};

export default DataManager;
