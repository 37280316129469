import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, isUserAdmin } from "../firebase";
import { DashboardLayout } from "./dashboard/DashboardLayout";

export function Dashboard() {
  const navigate = useNavigate();
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (auth.currentUser) {
        const adminStatus = await isUserAdmin(auth.currentUser.uid);
        setIsApproved(adminStatus);
      }
    };

    checkAdminStatus();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkAdminStatus();
      } else {
        setIsApproved(false);
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return <DashboardLayout isAdmin={isApproved} />;
}
