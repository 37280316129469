const getIpLocation = async () => {
  try {
    // Use ipapi.co instead of multiple API calls
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();

    if (data.error) {
      console.error("IP API error:", data.error);
      return null;
    }

    return {
      ip: data.ip,
      country: data.country_name,
      region: data.region,
    };
  } catch (error) {
    console.error("Error getting IP location:", error);
    return null;
  }
};

export default getIpLocation;
