import React, { useState, useEffect } from "react";
import BulkAddPurchasesModal from "./BulkAddPurchasesModal";
import {
  getDatabase,
  ref,
  onValue,
  push,
  remove,
  set,
  update,
  get,
} from "firebase/database";
import { auth } from "../../firebase";

const VerifiedPurchases = ({ isAdmin }) => {
  const [currentProject, setCurrentProject] = useState("");
  const [projects, setProjects] = useState({});
  const [purchases, setPurchases] = useState([]);
  const [showBulkAddModal, setShowBulkAddModal] = useState(false);
  const [error, setError] = useState("");
  const [toggleCount, setToggleCount] = useState(0);
  const [lastReset, setLastReset] = useState(null);
  const [totalPurchases, setTotalPurchases] = useState(0);

  useEffect(() => {
    const db = getDatabase();
    const projectsRef = ref(db, "verifiedPurchases");

    const unsubscribe = onValue(projectsRef, (snapshot) => {
      if (snapshot.exists()) {
        const projectsData = snapshot.val();
        setProjects(projectsData);
        if (!currentProject && Object.keys(projectsData).length > 0) {
          setCurrentProject(Object.keys(projectsData)[0]);
        }
      } else {
        setProjects({});
      }
    });

    return () => unsubscribe();
  }, [currentProject]);

  useEffect(() => {
    if (currentProject) {
      const db = getDatabase();
      const purchasesRef = ref(db, `verifiedPurchases/${currentProject}`);

      const unsubscribe = onValue(purchasesRef, (snapshot) => {
        if (snapshot.exists()) {
          const purchasesData = snapshot.val();
          const purchasesArray = Object.entries(purchasesData)
            .filter(([key]) => key !== "__PLACEHOLDER__")
            .map(([id, data]) => ({
              ...data,
              dbId: id,
            }));
          setPurchases(purchasesArray);
          setTotalPurchases(purchasesArray.length);
        } else {
          setPurchases([]);
          setTotalPurchases(0);
        }
      });

      return () => unsubscribe();
    }
  }, [currentProject]);

  useEffect(() => {
    if (!auth.currentUser) return;

    const db = getDatabase();
    const togglesRef = ref(
      db,
      `verifiedPurchaseToggles/${auth.currentUser.uid}`
    );

    const checkDailyReset = async () => {
      const today = new Date().toISOString().split("T")[0];

      const snapshot = await get(togglesRef);
      const userData = snapshot.val() || { count: 0, lastReset: "" };

      if (userData.lastReset !== today) {
        // Reset count for new day
        await set(togglesRef, {
          count: 0,
          lastReset: today,
        });
        setToggleCount(0);
        setLastReset(today);
      } else {
        setToggleCount(userData.count || 0);
        setLastReset(userData.lastReset);
      }
    };

    checkDailyReset();

    const unsubscribe = onValue(togglesRef, (snapshot) => {
      const data = snapshot.val() || { count: 0 };
      setToggleCount(data.count);
    });

    return () => unsubscribe();
  }, []);

  const handleBulkAdd = async (count) => {
    if (!isAdmin) return;

    try {
      const db = getDatabase();
      const projectRef = ref(db, `verifiedPurchases/${currentProject}`);
      const currentCount = purchases.length;

      const updates = {};
      updates["__PLACEHOLDER__"] = null;

      for (let i = 0; i < count; i++) {
        const newPurchaseRef = push(projectRef);
        updates[newPurchaseRef.key] = {
          id: currentCount + i + 1,
          verified: false,
        };
      }

      await update(ref(db, `verifiedPurchases/${currentProject}`), updates);
      setShowBulkAddModal(false);
    } catch (err) {
      setError("Failed to add purchases: " + err.message);
    }
  };

  const handleToggleVerified = async (dbId, currentVerified) => {
    if (!isAdmin && toggleCount >= 1000) {
      setError(
        "Daily toggle limit reached (1000/1000). Please try again tomorrow."
      );
      return;
    }

    try {
      const db = getDatabase();
      const purchaseRef = ref(
        db,
        `verifiedPurchases/${currentProject}/${dbId}`
      );

      // Update toggle count for non-admin users
      if (!isAdmin) {
        const togglesRef = ref(
          db,
          `verifiedPurchaseToggles/${auth.currentUser.uid}`
        );
        await update(togglesRef, {
          count: toggleCount + 1,
          lastReset: lastReset,
        });
      }

      await update(purchaseRef, { verified: !currentVerified });
      setError("");
    } catch (err) {
      setError("Failed to update verified status: " + err.message);
    }
  };

  const handleNewProject = async () => {
    if (!isAdmin) return;

    const projectName = prompt("Enter new project name:");
    if (projectName && projectName.trim()) {
      try {
        const db = getDatabase();
        const projectRef = ref(db, `verifiedPurchases/${projectName.trim()}`);
        await set(projectRef, {
          __PLACEHOLDER__: "__EMPTY__",
        });
        setCurrentProject(projectName.trim());
      } catch (err) {
        setError("Failed to create project: " + err.message);
      }
    }
  };

  const handleDeleteProject = async () => {
    if (!isAdmin) return;

    try {
      const db = getDatabase();
      const projectRef = ref(db, `verifiedPurchases/${currentProject}`);
      await remove(projectRef);
    } catch (err) {
      setError("Failed to delete project: " + err.message);
    }
  };

  const handleDeleteRow = async (dbId) => {
    if (!isAdmin) return;

    try {
      const db = getDatabase();
      const purchaseRef = ref(
        db,
        `verifiedPurchases/${currentProject}/${dbId}`
      );
      await remove(purchaseRef);
    } catch (err) {
      setError("Failed to delete purchase: " + err.message);
    }
  };

  // Calculate verified count
  const verifiedCount = purchases.filter(
    (purchase) => purchase.verified
  ).length;
  const progressPercentage =
    totalPurchases > 0 ? Math.round((verifiedCount / totalPurchases) * 100) : 0;

  return (
    <div className="space-y-6">
      {/* Error Display */}
      {error && (
        <div className="bg-red-500 text-white p-3 rounded-lg mb-4">{error}</div>
      )}

      {/* Toggle count display for regular users */}
      {!isAdmin && (
        <div className="text-sm text-gray-400 mb-4">
          Daily toggles: {toggleCount}/1000
        </div>
      )}

      {/* Progress Counter Display */}
      <div className="text-sm text-gray-400 mb-4">
        Progress: {progressPercentage}% ({verifiedCount}/{totalPurchases})
      </div>

      {/* Project Selection and Controls */}
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <select
              value={currentProject}
              onChange={(e) => setCurrentProject(e.target.value)}
              className="p-2 rounded bg-gray-800 text-white border border-gray-700"
            >
              {Object.keys(projects).map((project) => (
                <option key={project} value={project}>
                  {project}
                </option>
              ))}
            </select>

            {isAdmin && (
              <>
                <button
                  onClick={handleNewProject}
                  className="px-3 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
                >
                  New Project
                </button>
                <button
                  onClick={handleDeleteProject}
                  className="px-3 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                  disabled={Object.keys(projects).length === 0}
                >
                  Delete Project
                </button>
              </>
            )}
          </div>

          {isAdmin && (
            <div className="flex gap-2">
              <button
                onClick={() => setShowBulkAddModal(true)}
                className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
              >
                Bulk Add
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Purchases Table */}
      <div className="max-w-2xl mx-auto bg-[#1E1E1E] rounded-lg overflow-hidden">
        <table className="w-full">
          <thead className="bg-[#27272A]">
            <tr>
              <th className="p-3 text-left text-gray-300 font-medium w-24">
                Index
              </th>
              <th className="p-3 text-left text-gray-300 font-medium w-24">
                Verified
              </th>
              {isAdmin && (
                <th className="p-3 text-left text-gray-300 font-medium w-24">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {purchases.map((purchase) => (
              <tr key={purchase.dbId} className="border-b border-gray-800">
                <td className="p-3 text-gray-300">{purchase.id}</td>
                <td className="p-3 text-gray-300">
                  <input
                    type="checkbox"
                    checked={purchase.verified}
                    onChange={() =>
                      handleToggleVerified(purchase.dbId, purchase.verified)
                    }
                    className="form-checkbox h-4 w-4 text-blue-600 rounded bg-gray-800 border-gray-600 checked:bg-blue-600 checked:border-white"
                  />
                </td>
                {isAdmin && (
                  <td className="p-3">
                    <button
                      onClick={() => handleDeleteRow(purchase.dbId)}
                      className="px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
            {purchases.length === 0 && (
              <tr>
                <td
                  colSpan={isAdmin ? 3 : 2}
                  className="p-4 text-center text-gray-500"
                >
                  No purchases added yet. Use the Bulk Add button to add
                  purchases.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Bulk Add Modal */}
      {showBulkAddModal && (
        <BulkAddPurchasesModal
          onAdd={handleBulkAdd}
          onClose={() => setShowBulkAddModal(false)}
        />
      )}
    </div>
  );
};

export default VerifiedPurchases;
