import React, { useState, useEffect } from "react";

const EstimatedEarnings = ({ isAdmin }) => {
  const [input, setInput] = useState("");
  const [earnings, setEarnings] = useState({ usd: null, cad: null });
  const [exchangeRate, setExchangeRate] = useState(null);
  const [customRate, setCustomRate] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch(
          "https://www.bankofcanada.ca/valet/observations/FXUSDCAD/json?recent=1"
        );
        const data = await response.json();
        const rate = parseFloat(data.observations[0].FXUSDCAD.v);
        setExchangeRate(rate);
        setError(null);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
        setError(
          "Error fetching USD to CAD exchange rate. Please enter manually (e.g. 1.35):"
        );
        setExchangeRate(null);
      }
    };

    fetchExchangeRate();
  }, []);

  const handleCustomRateSubmit = (e) => {
    e.preventDefault();
    const rate = parseFloat(customRate);
    if (!isNaN(rate) && rate > 0) {
      setExchangeRate(rate);
      setError(null);
      calculateEarnings(input, rate);
    } else {
      setError("Please enter a valid exchange rate");
    }
  };

  const calculateEarnings = (value, rate = exchangeRate) => {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && rate) {
      const usdEarnings = numericValue / 25.8;
      const cadEarnings = usdEarnings * rate;

      setEarnings({
        usd: usdEarnings.toFixed(2),
        cad: cadEarnings.toFixed(2),
      });
    } else {
      setEarnings({ usd: null, cad: null });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    calculateEarnings(value);
  };

  return (
    <div className="w-full max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg">
      <div className="p-4 border-b border-gray-800">
        <h2 className="text-lg font-bold text-fortpixel-gold">
          Estimated Earnings Calculator
        </h2>
      </div>

      <div className="p-4">
        <div className="space-y-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="earnings-input" className="text-sm font-medium">
              Enter Plays
            </label>
            <input
              id="earnings-input"
              type="number"
              value={input}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter number of plays..."
            />
          </div>

          {error && (
            <form onSubmit={handleCustomRateSubmit} className="space-y-2">
              <div className="p-3 bg-red-900/50 border border-red-700 rounded-md">
                <span className="text-sm text-red-400">{error}</span>
              </div>
              <div className="flex gap-2">
                <input
                  type="number"
                  value={customRate}
                  onChange={(e) => setCustomRate(e.target.value)}
                  className="flex-1 px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter USD to CAD rate"
                  step="0.0001"
                />
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-md text-sm"
                >
                  Set Rate
                </button>
              </div>
            </form>
          )}

          {exchangeRate !== null && earnings.usd !== null && (
            <div className="space-y-3">
              <div className="p-3 bg-gray-800 rounded-md">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium">
                    Estimated Earnings (USD):
                  </span>
                  <span className="text-lg font-bold text-blue-400">
                    US${earnings.usd}
                  </span>
                </div>
              </div>

              <div className="p-3 bg-gray-800 rounded-md">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium">
                    Estimated Earnings (CAD):
                  </span>
                  <span className="text-lg font-bold text-green-400">
                    CA${earnings.cad}
                  </span>
                </div>
              </div>

              <div className="p-3 bg-gray-800 rounded-md">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium">
                    Split Amount (CAD):
                  </span>
                  <span className="text-lg font-bold text-purple-400">
                    CA${(parseFloat(earnings.cad) / 2).toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="text-xs text-gray-400 text-right">
                Current Rate: 1 USD = {exchangeRate.toFixed(4)} CAD
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EstimatedEarnings;
