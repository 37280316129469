import React, { useState, useEffect, useRef, useCallback } from "react";

const VirtualList = ({ items, onRemove, onEdit }) => {
  const [visibleItems, setVisibleItems] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [editingId, setEditingId] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [copyButtonTexts, setCopyButtonTexts] = useState({});
  const containerRef = useRef(null);

  // Constants for virtualization
  const ITEM_HEIGHT = 48; // Height of each row in pixels
  const BUFFER_ITEMS = 5; // Number of items to render above/below visible area

  const updateVisibleItems = useCallback(
    (currentScrollTop) => {
      if (!containerRef.current) return;

      const containerHeight = containerRef.current.clientHeight;

      // Calculate visible range
      const startIndex = Math.max(
        0,
        Math.floor(currentScrollTop / ITEM_HEIGHT) - BUFFER_ITEMS
      );
      const visibleCount =
        Math.ceil(containerHeight / ITEM_HEIGHT) + 2 * BUFFER_ITEMS;
      const endIndex = Math.min(startIndex + visibleCount, items.length);

      // Update visible items
      const newVisibleItems = items
        .slice(startIndex, endIndex)
        .map((item, index) => ({
          ...item,
          virtualIndex: startIndex + index,
        }));

      setVisibleItems(newVisibleItems);
    },
    [items, ITEM_HEIGHT, BUFFER_ITEMS]
  );

  useEffect(() => {
    updateVisibleItems(scrollTop);
  }, [scrollTop, updateVisibleItems]);

  const handleScroll = (e) => {
    setScrollTop(e.target.scrollTop);
  };

  const handleStartEdit = (id, currentValue) => {
    setEditingId(id);
    setEditValue(currentValue);
  };

  const handleSaveEdit = (id) => {
    if (editValue.trim()) {
      onEdit(id, editValue.trim());
    }
    setEditingId(null);
    setEditValue("");
  };

  const handleKeyPress = (e, id) => {
    if (e.key === "Enter") {
      handleSaveEdit(id);
    } else if (e.key === "Escape") {
      setEditingId(null);
      setEditValue("");
    }
  };

  const handleCopyToClipboard = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        setCopyButtonTexts((prev) => ({
          ...prev,
          [id]: "Copied!",
        }));
        setTimeout(() => {
          setCopyButtonTexts((prev) => ({
            ...prev,
            [id]: "Copy",
          }));
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      className="h-96 overflow-y-auto border border-gray-700 rounded-lg bg-gray-800"
    >
      <table className="w-full table-fixed">
        <colgroup>
          <col className="w-1/6" /> {/* ID column */}
          <col className="w-3/6" /> {/* Name column */}
          <col className="w-2/6" /> {/* Action column */}
        </colgroup>
        <thead className="sticky top-0 bg-gray-800 z-10">
          <tr className="border-b border-gray-700">
            <th className="p-3 text-left text-gray-300 font-semibold truncate">
              ID
            </th>
            <th className="p-3 text-left text-gray-300 font-semibold truncate">
              Name
            </th>
            <th className="p-3 text-left text-gray-300 font-semibold truncate">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Spacer for items above */}
          <tr>
            <td
              colSpan="3"
              style={{
                height: `${visibleItems[0]?.virtualIndex * ITEM_HEIGHT}px`,
              }}
            />
          </tr>

          {/* Visible items */}
          {visibleItems.map(({ 0: id, 1: name, virtualIndex }) => (
            <tr
              key={id}
              className="border-b border-gray-700 hover:bg-gray-700 transition-colors"
              style={{ height: `${ITEM_HEIGHT}px` }}
            >
              <td className="p-3 text-gray-300 truncate">{id}</td>
              <td className="p-3 text-gray-300 truncate">
                {editingId === id ? (
                  <input
                    type="text"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    onKeyDown={(e) => handleKeyPress(e, id)}
                    onBlur={() => handleSaveEdit(id)}
                    className="w-full p-1 bg-gray-900 text-white rounded border border-gray-600"
                    autoFocus
                    maxLength={50}
                  />
                ) : (
                  name
                )}
              </td>
              <td className="p-3">
                <div className="flex gap-2">
                  <button
                    onClick={() => handleCopyToClipboard(id)}
                    className="px-3 py-1 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
                  >
                    {copyButtonTexts[id] || "Copy"}
                  </button>
                  {editingId === id ? (
                    <button
                      onClick={() => handleSaveEdit(id)}
                      className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
                      onMouseDown={(e) => e.preventDefault()} // Prevent onBlur from firing before click
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => handleStartEdit(id, name)}
                      className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                    >
                      Edit
                    </button>
                  )}
                  <button
                    onClick={() => onRemove(id)}
                    className="px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                  >
                    Remove
                  </button>
                </div>
              </td>
            </tr>
          ))}

          {/* Spacer for items below */}
          <tr>
            <td
              colSpan="3"
              style={{
                height: `${Math.max(
                  0,
                  (items.length -
                    (visibleItems[visibleItems.length - 1]?.virtualIndex + 1 ||
                      0)) *
                    ITEM_HEIGHT
                )}px`,
              }}
            />
          </tr>
        </tbody>
      </table>
      <div className="p-3 text-center text-gray-400 bg-gray-800 border-t border-gray-700 sticky bottom-0">
        Showing {items.length} items
      </div>
    </div>
  );
};

export default VirtualList;
