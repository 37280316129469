import React, { useState } from "react";

const BulkAddPurchasesModal = ({ onAdd, onClose }) => {
  const [count, setCount] = useState("");

  const handleSubmit = () => {
    const numCount = parseInt(count);
    if (numCount > 0) {
      onAdd(numCount);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-6 rounded-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-white">
          Add Multiple Purchases
        </h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm text-gray-300 mb-2">
              Number of purchases to add:
            </label>
            <input
              type="number"
              value={count}
              onChange={(e) => setCount(e.target.value)}
              className="w-full p-2 rounded bg-gray-800 text-white"
              min="1"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkAddPurchasesModal;
