import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

export function DashboardLayout({ isAdmin }) {
  const navigate = useNavigate();
  const [viewAsUser, setViewAsUser] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Compute effective admin status based on toggle
  const effectiveAdminStatus = isAdmin && !viewAsUser;

  return (
    <div className="min-h-screen bg-[#09090B]">
      <nav className="bg-[#18181B] border-b border-[#27272A]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            {/* Navigation Links */}
            <div className="flex items-center space-x-4">
              <NavLink
                to="/dashboard/tycoon"
                className={({ isActive }) =>
                  `px-4 py-2 text-sm font-medium rounded-md transition-all duration-200 ease-in-out ${
                    isActive
                      ? "bg-[#3F3F46] text-fortpixel-gold shadow-lg shadow-black/20"
                      : "text-gray-300 hover:text-white hover:bg-[#27272A]"
                  }`
                }
              >
                Tycoon Requires
              </NavLink>
              <NavLink
                to="/dashboard/verified"
                className={({ isActive }) =>
                  `px-4 py-2 text-sm font-medium rounded-md transition-all duration-200 ease-in-out ${
                    isActive
                      ? "bg-[#3F3F46] text-fortpixel-gold shadow-lg shadow-black/20"
                      : "text-gray-300 hover:text-white hover:bg-[#27272A]"
                  }`
                }
              >
                Verified Purchases
              </NavLink>
              <NavLink
                to="/dashboard/calculator"
                className={({ isActive }) =>
                  `px-4 py-2 text-sm font-medium rounded-md transition-all duration-200 ease-in-out ${
                    isActive
                      ? "bg-[#3F3F46] text-fortpixel-gold shadow-lg shadow-black/20"
                      : "text-gray-300 hover:text-white hover:bg-[#27272A]"
                  }`
                }
              >
                Earnings Calculator
              </NavLink>
              <NavLink
                to="/dashboard/expenses"
                className={({ isActive }) =>
                  `px-4 py-2 text-sm font-medium rounded-md transition-all duration-200 ease-in-out ${
                    isActive
                      ? "bg-[#3F3F46] text-fortpixel-gold shadow-lg shadow-black/20"
                      : "text-gray-300 hover:text-white hover:bg-[#27272A]"
                  }`
                }
              >
                Expenses
              </NavLink>
              <NavLink
                to="/dashboard/metrics"
                className={({ isActive }) =>
                  `px-4 py-2 text-sm font-medium rounded-md transition-all duration-200 ease-in-out ${
                    isActive
                      ? "bg-[#3F3F46] text-fortpixel-gold shadow-lg shadow-black/20"
                      : "text-gray-300 hover:text-white hover:bg-[#27272A]"
                  }`
                }
              >
                Metrics Chart
              </NavLink>
            </div>

            {/* User Controls */}
            <div className="flex items-center gap-4">
              {isAdmin && (
                <button
                  onClick={() => setViewAsUser(!viewAsUser)}
                  className="flex items-center gap-2 bg-[#27272A] px-3 py-1.5 rounded-full shadow-lg shadow-black/20 hover:bg-[#3F3F46] transition-colors cursor-pointer"
                >
                  <div
                    className={`w-2 h-2 rounded-full ${
                      !viewAsUser ? "bg-fortpixel-gold" : "bg-gray-500"
                    }`}
                  ></div>
                  <span className="text-sm text-gray-300">
                    {!viewAsUser ? "Admin" : "Viewing as User"}
                  </span>
                </button>
              )}
              {!isAdmin && (
                <div className="flex items-center gap-2 bg-[#27272A] px-3 py-1.5 rounded-full shadow-lg shadow-black/20">
                  <div className="w-2 h-2 rounded-full bg-gray-500"></div>
                  <span className="text-sm text-gray-300">User</span>
                </div>
              )}
              <button
                onClick={handleLogout}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-md transition-colors duration-200 ease-in-out shadow-lg shadow-black/20"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet context={{ isAdmin: effectiveAdminStatus }} />
      </main>
    </div>
  );
}
