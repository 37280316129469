import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, push, get, update } from "firebase/database";
import { auth } from "../firebase";
import getIpLocation from "../utils/ipLocation";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      console.log("Attempting login...");
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      console.log("Login successful, getting location data...");
      const locationData = await getIpLocation();

      if (locationData) {
        try {
          const db = getDatabase();
          const userLogsRef = ref(
            db,
            `loginLogs/${userCredential.user.uid}/locations`
          );
          console.log("Checking existing logs...");

          // Get existing logs
          const snapshot = await get(userLogsRef);
          const existingLogs = snapshot.val() || {};
          console.log("Existing logs:", existingLogs);

          // Check if this IP already exists
          const existingEntry = Object.entries(existingLogs).find(
            ([_, data]) => data.ip === locationData.ip
          );

          if (existingEntry) {
            console.log("Updating existing IP entry...");
            const [key, data] = existingEntry;
            const timestamps = data.timestamps || [];
            timestamps.push(new Date().toISOString());

            while (timestamps.length > 100) timestamps.shift();

            await update(
              ref(db, `loginLogs/${userCredential.user.uid}/locations/${key}`),
              {
                timestamps,
              }
            );
            console.log("Updated existing entry successfully");
          } else {
            console.log("Creating new IP entry...");
            const newLocation = {
              ...locationData,
              timestamps: [new Date().toISOString()],
            };
            await push(userLogsRef, newLocation);
            console.log("Created new entry successfully");
          }
        } catch (dbError) {
          console.error("Database error:", dbError);
        }
      }

      console.log("Navigating to dashboard...");
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      switch (error.code) {
        case "auth/invalid-email":
        case "auth/user-disabled":
        case "auth/user-not-found":
        case "auth/wrong-password":
        case "auth/invalid-credential":
          setError("Invalid email or password.");
          break;
        case "auth/network-request-failed":
          setError("Network error. Please check your connection.");
          break;
        case "auth/too-many-requests":
          setError("Too many attempts. Please try again later.");
          break;
        default:
          setError(`Login error: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black text-white">
      <div className="w-full max-w-md bg-gray-900 rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-bold mb-6 text-center text-fortpixel-gold">
          Admin Login
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6" autoComplete="on">
          <div>
            <label className="block text-sm font-medium mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 rounded-md text-white"
              required
              name="email"
              autoComplete="username"
              disabled={isLoading}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 rounded-md text-white"
              required
              name="current-password"
              autoComplete="current-password"
              disabled={isLoading}
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            className="w-full py-2 px-4 bg-fortpixel-gold hover:bg-yellow-600 text-black font-medium rounded-md disabled:opacity-50"
            disabled={isLoading}
          >
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
}
