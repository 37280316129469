import React, { useState, useEffect, useMemo } from "react";
import {
  getDatabase,
  ref,
  push,
  remove,
  update,
  onValue,
} from "firebase/database";

const ExpensesManager = ({ isAdmin }) => {
  const [expenses, setExpenses] = useState([]);
  const [newExpense, setNewExpense] = useState({
    name: "",
    date: "",
    usd: "",
    cad: "",
    split: false,
  });
  const [error, setError] = useState("");
  const [showOnlyUnsplit, setShowOnlyUnsplit] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  useEffect(() => {
    const db = getDatabase();
    const expensesRef = ref(db, "expenses");

    const unsubscribe = onValue(expensesRef, (snapshot) => {
      if (snapshot.exists()) {
        const expensesData = snapshot.val();
        const expensesArray = Object.entries(expensesData).map(
          ([id, data]) => ({
            id,
            ...data,
          })
        );
        setExpenses(expensesArray);
      } else {
        setExpenses([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const sortedAndFilteredExpenses = useMemo(() => {
    let filteredExpenses = [...expenses];

    // Apply filter if showOnlyUnsplit is true
    if (showOnlyUnsplit) {
      filteredExpenses = filteredExpenses.filter((expense) => !expense.split);
    }

    // Apply sorting
    filteredExpenses.sort((a, b) => {
      if (sortConfig.key === "date") {
        const dateA = new Date(a[sortConfig.key]);
        const dateB = new Date(b[sortConfig.key]);
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (sortConfig.key === "usd" || sortConfig.key === "cad") {
        const valueA = parseFloat(a[sortConfig.key]) || 0;
        const valueB = parseFloat(b[sortConfig.key]) || 0;
        return sortConfig.direction === "asc"
          ? valueA - valueB
          : valueB - valueA;
      }

      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    return filteredExpenses;
  }, [expenses, showOnlyUnsplit, sortConfig]);

  const totalUnsplitAmount = useMemo(() => {
    return expenses
      .filter((expense) => !expense.split)
      .reduce((total, expense) => total + (parseFloat(expense.cad) || 0), 0);
  }, [expenses]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const calculateSplit = (amount) => {
    const value = parseFloat(amount);
    if (isNaN(value)) return "-";
    return (value / 2).toFixed(2);
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handleAddExpense = async () => {
    if (!newExpense.name || !newExpense.date || !newExpense.cad) {
      setError("Name, Date, and CAD amount are required");
      return;
    }

    try {
      const db = getDatabase();
      const expensesRef = ref(db, "expenses");

      // Create expense object, omitting empty USD field
      const expenseData = {
        name: newExpense.name,
        date: newExpense.date,
        cad: newExpense.cad,
        split: newExpense.split,
      };

      // Only add USD if it has a value
      if (newExpense.usd) {
        expenseData.usd = newExpense.usd;
      }

      await push(expensesRef, expenseData);

      setNewExpense({ name: "", date: "", usd: "", cad: "", split: false });
      setError("");
    } catch (err) {
      setError("Failed to add expense: " + err.message);
    }
  };

  const handleRemoveExpense = async (id) => {
    try {
      const db = getDatabase();
      const expenseRef = ref(db, `expenses/${id}`);
      await remove(expenseRef);
    } catch (err) {
      setError("Failed to remove expense: " + err.message);
    }
  };

  const handleToggleSplit = async (id, currentSplit) => {
    try {
      const db = getDatabase();
      const expenseRef = ref(db, `expenses/${id}`);
      await update(expenseRef, { split: !currentSplit });
    } catch (err) {
      setError("Failed to update split status: " + err.message);
    }
  };

  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    return sortConfig.direction === "asc" ? "↑" : "↓";
  };

  return (
    <div className="space-y-6">
      {/* Filter and Total Section */}
      <div className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
        <div className="flex items-center space-x-4">
          <label className="flex items-center space-x-2 text-white">
            <input
              type="checkbox"
              checked={showOnlyUnsplit}
              onChange={(e) => setShowOnlyUnsplit(e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600 rounded bg-gray-700 border-gray-600"
            />
            <span>Show Unpaid Split Only</span>
          </label>
        </div>
        <div className="text-white">
          <span className="font-medium">Total Split Remainder: </span>
          <span className="text-green-400">
            ${(totalUnsplitAmount / 2).toFixed(2)}
          </span>
        </div>
      </div>

      {/* Input Form - Only visible to admin */}
      {isAdmin && (
        <>
          <div className="grid grid-cols-6 gap-4">
            <input
              type="text"
              value={newExpense.name}
              onChange={(e) =>
                setNewExpense({ ...newExpense, name: e.target.value })
              }
              placeholder="Purchase Name"
              className="p-2 rounded bg-gray-800 text-white"
            />
            <input
              type="date"
              value={newExpense.date}
              onChange={(e) =>
                setNewExpense({ ...newExpense, date: e.target.value })
              }
              className="p-2 rounded bg-gray-800 text-white"
            />
            <input
              type="number"
              value={newExpense.usd}
              onChange={(e) =>
                setNewExpense({ ...newExpense, usd: e.target.value })
              }
              placeholder="USD (optional)"
              className="p-2 rounded bg-gray-800 text-white"
            />
            <input
              type="number"
              value={newExpense.cad}
              onChange={(e) =>
                setNewExpense({ ...newExpense, cad: e.target.value })
              }
              placeholder="CAD"
              className="p-2 rounded bg-gray-800 text-white"
            />
            <div className="flex items-center">
              <label className="flex items-center space-x-2 text-white">
                <input
                  type="checkbox"
                  checked={newExpense.split}
                  onChange={(e) =>
                    setNewExpense({ ...newExpense, split: e.target.checked })
                  }
                  className="form-checkbox h-4 w-4 text-blue-600 rounded bg-gray-800 border-gray-600"
                />
                <span>Split Paid</span>
              </label>
            </div>
            <button
              onClick={handleAddExpense}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            >
              Add Expense
            </button>
          </div>

          {error && <div className="text-red-500 text-sm">{error}</div>}
        </>
      )}

      {/* Expenses Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-800">
            <tr>
              <th
                className="p-3 text-left text-gray-300 cursor-pointer hover:bg-gray-700"
                onClick={() => handleSort("name")}
              >
                Purchase Name {renderSortIcon("name")}
              </th>
              <th
                className="p-3 text-left text-gray-300 cursor-pointer hover:bg-gray-700"
                onClick={() => handleSort("date")}
              >
                Date {renderSortIcon("date")}
              </th>
              <th
                className="p-3 text-left text-gray-300 cursor-pointer hover:bg-gray-700"
                onClick={() => handleSort("usd")}
              >
                USD {renderSortIcon("usd")}
              </th>
              <th
                className="p-3 text-left text-gray-300 cursor-pointer hover:bg-gray-700"
                onClick={() => handleSort("cad")}
              >
                CAD {renderSortIcon("cad")}
              </th>
              <th className="p-3 text-left text-gray-300">Split</th>
              <th
                className="p-3 text-left text-gray-300 cursor-pointer hover:bg-gray-700"
                onClick={() => handleSort("split")}
              >
                Split Paid {renderSortIcon("split")}
              </th>
              {isAdmin && (
                <th className="p-3 text-left text-gray-300">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedAndFilteredExpenses.map((expense) => (
              <tr key={expense.id} className="border-b border-gray-700">
                <td className="p-3 text-gray-300">{expense.name}</td>
                <td className="p-3 text-gray-300">
                  {formatDate(expense.date)}
                </td>
                <td className="p-3 text-gray-300">
                  {expense.usd ? `$${expense.usd}` : "-"}
                </td>
                <td className="p-3 text-gray-300">${expense.cad}</td>
                <td className="p-3 text-gray-300">
                  ${calculateSplit(expense.cad)}
                </td>
                <td className="p-3 text-gray-300">
                  {isAdmin ? (
                    <input
                      type="checkbox"
                      checked={expense.split}
                      onChange={() =>
                        handleToggleSplit(expense.id, expense.split)
                      }
                      className="form-checkbox h-4 w-4 text-blue-600 rounded bg-gray-800 border-gray-600"
                    />
                  ) : (
                    <span>{expense.split ? "Yes" : "No"}</span>
                  )}
                </td>
                {isAdmin && (
                  <td className="p-3">
                    <button
                      onClick={() => handleRemoveExpense(expense.id)}
                      className="px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700"
                    >
                      Remove
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpensesManager;
