import React from "react";
import VerifiedPurchases from "./VerifiedPurchases";
import { useOutletContext } from "react-router-dom";

export function VerifiedPurchasesPage() {
  const { isAdmin } = useOutletContext();

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-200">
          Verified Purchases
        </h2>
        <p className="mt-2 text-sm text-gray-400">Verify Tycoon Purchases</p>
      </div>
      <div className="bg-[#18181B] rounded-xl shadow-xl shadow-black/20 p-6">
        <VerifiedPurchases isAdmin={isAdmin} />
      </div>
    </div>
  );
}
