import React, { useState, useCallback, useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import html2canvas from "html2canvas";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const parseCSV = (content) => {
  const lines = content.split("\n").filter((line) => line.trim());
  // Skip header row and parse data
  const data = lines
    .slice(1)
    .map((line) => {
      // Split on comma but handle quoted values - fixed regex
      const matches = line.match(/"([^"]*)",?"([^"]*)"/);
      if (matches && matches.length >= 3) {
        const date = matches[1];
        const value = matches[2].replace(/,/g, "");
        return {
          date: date.trim(),
          value: parseInt(value, 10) || 0, // Handle invalid numbers
        };
      }
      return null;
    })
    .filter((item) => item !== null); // Remove any invalid rows
  return data;
};

const formatDate = (dateStr) => {
  // Convert "2025-01-17" to "1/17/2025"
  const [year, month, day] = dateStr.split("-");
  return `${parseInt(month)}/${parseInt(day)}/${year}`;
};

const MetricsChart = () => {
  const [metricsData, setMetricsData] = useState({
    clicks: null,
    impressions: null,
    plays: null,
  });
  const chartContainerRef = useRef(null);
  const [mapName, setMapName] = useState("");
  const [showNamePrompt, setShowNamePrompt] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result;
        const data = parseCSV(content);

        // Detect file type based on column name in header
        const headerLine = content.split("\n")[0].toLowerCase();
        if (headerLine.includes("clicks")) {
          setMetricsData((prev) => ({ ...prev, clicks: data }));
        } else if (headerLine.includes("impressions")) {
          setMetricsData((prev) => ({ ...prev, impressions: data }));
        } else if (headerLine.includes("plays")) {
          setMetricsData((prev) => ({ ...prev, plays: data }));
        }
      };
      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const processData = useMemo(() => {
    if (!metricsData.clicks || !metricsData.impressions || !metricsData.plays) {
      return null;
    }

    // Create a map of all dates
    const dateMap = new Map();

    // Initialize with all dates from all sources
    [
      ...metricsData.clicks,
      ...metricsData.impressions,
      ...metricsData.plays,
    ].forEach((item) => {
      if (!dateMap.has(item.date)) {
        dateMap.set(item.date, {
          date: item.date,
          impressions: 0,
          clicks: 0,
          plays: 0,
        });
      }
    });

    // Combine data for each date
    metricsData.clicks.forEach((item) => {
      if (dateMap.has(item.date)) {
        dateMap.get(item.date).clicks = item.value;
      }
    });
    metricsData.impressions.forEach((item) => {
      if (dateMap.has(item.date)) {
        dateMap.get(item.date).impressions = item.value;
      }
    });
    metricsData.plays.forEach((item) => {
      if (dateMap.has(item.date)) {
        dateMap.get(item.date).plays = item.value;
      }
    });

    // Filter dates that have data from all sources and sort by date
    return Array.from(dateMap.values())
      .filter(
        (item) => item.clicks > 0 || item.impressions > 0 || item.plays > 0
      )
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((item) => ({
        ...item,
        formattedDate: formatDate(item.date),
        ctr:
          item.impressions > 0
            ? ((item.clicks / item.impressions) * 100).toFixed(2)
            : "0.00",
        ctp:
          item.clicks > 0
            ? ((item.plays / item.clicks) * 100).toFixed(2)
            : "0.00",
      }));
  }, [metricsData]);

  const chartData = useMemo(() => {
    if (!processData) return null;

    return {
      labels: processData.map((item) => item.formattedDate),
      datasets: [
        {
          label: "CTR (%)",
          data: processData.map((item) => parseFloat(item.ctr)),
          borderColor: "#10B981",
          backgroundColor: "#10B981",
          tension: 0.1,
        },
        {
          label: "CTP (%)",
          data: processData.map((item) => parseFloat(item.ctp)),
          borderColor: "#F59E0B",
          backgroundColor: "#F59E0B",
          tension: 0.1,
        },
        {
          label: "Clicks",
          data: processData.map((item) => item.clicks),
          borderColor: "#3B82F6",
          backgroundColor: "#3B82F6",
          tension: 0.1,
          hidden: true,
          yAxisID: "y1",
        },
        {
          label: "Impressions",
          data: processData.map((item) => item.impressions),
          borderColor: "#EC4899",
          backgroundColor: "#EC4899",
          tension: 0.1,
          hidden: true,
          yAxisID: "y1",
        },
        {
          label: "Plays",
          data: processData.map((item) => item.plays),
          borderColor: "#8B5CF6",
          backgroundColor: "#8B5CF6",
          tension: 0.1,
          hidden: true,
          yAxisID: "y1",
        },
      ],
    };
  }, [processData]);

  const handleSaveClick = () => {
    setShowNamePrompt(true);
  };

  const handleSaveImage = async (e) => {
    e?.preventDefault(); // Handle form submission
    if (!chartContainerRef.current || !mapName.trim()) return;

    try {
      const canvas = await html2canvas(chartContainerRef.current, {
        backgroundColor: "#1F2937",
        scale: 2,
        logging: false,
        useCORS: true,
      });

      const link = document.createElement("a");
      const date = new Date().toISOString().split("T")[0];
      link.download = `${mapName.trim()}-metrics-${date}.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();

      // Reset after saving
      setShowNamePrompt(false);
      setMapName("");
    } catch (error) {
      console.error("Error saving image:", error);
    }
  };

  return (
    <div className="space-y-6">
      <div
        {...getRootProps()}
        className={`p-8 border-2 border-dashed rounded-lg text-center cursor-pointer transition-colors
          ${
            isDragActive
              ? "border-blue-500 bg-blue-50/10"
              : "border-gray-600 hover:border-gray-500"
          }`}
      >
        <input {...getInputProps()} />
        <p className="text-gray-300">
          {isDragActive
            ? "Drop the files here..."
            : "Drag and drop metrics files here, or click to select files"}
        </p>
        <p className="text-sm text-gray-500 mt-2">
          Upload clicks, impressions, and plays CSV files
        </p>
      </div>

      <div className="space-y-2">
        <div className="text-sm text-gray-400">
          Status:
          <ul className="list-disc pl-5 mt-1">
            <li
              className={
                metricsData.clicks ? "text-green-500" : "text-gray-500"
              }
            >
              Clicks: {metricsData.clicks ? "Loaded" : "Waiting"}
            </li>
            <li
              className={
                metricsData.impressions ? "text-green-500" : "text-gray-500"
              }
            >
              Impressions: {metricsData.impressions ? "Loaded" : "Waiting"}
            </li>
            <li
              className={metricsData.plays ? "text-green-500" : "text-gray-500"}
            >
              Plays: {metricsData.plays ? "Loaded" : "Waiting"}
            </li>
          </ul>
        </div>
      </div>

      {showNamePrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
            <form onSubmit={handleSaveImage} className="space-y-4">
              <h3 className="text-lg font-medium text-gray-200">
                Enter Map Name
              </h3>
              <input
                type="text"
                value={mapName}
                onChange={(e) => setMapName(e.target.value)}
                placeholder="Enter map name..."
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                autoFocus
              />
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowNamePrompt(false);
                    setMapName("");
                  }}
                  className="px-4 py-2 bg-gray-700 text-gray-300 rounded-md hover:bg-gray-600 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!mapName.trim()}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {chartData && (
        <div className="space-y-4">
          <div className="flex justify-end">
            <button
              onClick={handleSaveClick}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center gap-2"
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
              Save as Image
            </button>
          </div>

          <div
            ref={chartContainerRef}
            className="space-y-6 bg-gray-800 p-6 rounded-lg"
          >
            <Line
              data={chartData}
              options={{
                responsive: true,
                interaction: {
                  mode: "index",
                  intersect: false,
                },
                plugins: {
                  legend: {
                    position: "top",
                    labels: {
                      color: "#E5E7EB",
                      font: {
                        size: 12,
                      },
                      usePointStyle: true,
                      padding: 15,
                    },
                    onClick: function (e, legendItem, legend) {
                      const index = legendItem.datasetIndex;
                      const ci = legend.chart;
                      if (ci.isDatasetVisible(index)) {
                        ci.hide(index);
                        legendItem.hidden = true;
                      } else {
                        ci.show(index);
                        legendItem.hidden = false;
                      }
                    },
                  },
                  title: {
                    display: true,
                    text: "Metrics Analysis",
                    color: "#E5E7EB",
                    font: {
                      size: 16,
                      weight: "bold",
                    },
                  },
                  tooltip: {
                    backgroundColor: "#374151",
                    titleColor: "#E5E7EB",
                    bodyColor: "#E5E7EB",
                    padding: 12,
                    cornerRadius: 4,
                  },
                },
                scales: {
                  y: {
                    type: "linear",
                    display: true,
                    position: "left",
                    title: {
                      display: true,
                      text: "Percentage (%)",
                      color: "#E5E7EB",
                    },
                    grid: {
                      color: "rgba(229, 231, 235, 0.1)",
                    },
                    ticks: {
                      color: "#E5E7EB",
                      font: {
                        size: 11,
                      },
                    },
                  },
                  y1: {
                    type: "linear",
                    display: true,
                    position: "right",
                    title: {
                      display: true,
                      text: "Count",
                      color: "#E5E7EB",
                    },
                    grid: {
                      drawOnChartArea: false,
                    },
                    ticks: {
                      color: "#E5E7EB",
                      font: {
                        size: 11,
                      },
                    },
                  },
                  x: {
                    grid: {
                      color: "rgba(229, 231, 235, 0.1)",
                    },
                    ticks: {
                      color: "#E5E7EB",
                      font: {
                        size: 11,
                      },
                    },
                  },
                },
                animation: {
                  duration: 0, // Disable animations for better image capture
                },
              }}
            />

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-900">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      Impressions
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      Clicks
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      Plays
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      CTR (%)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      CTP (%)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-800 divide-y divide-gray-700">
                  {processData.map((row, index) => (
                    <tr
                      key={row.date}
                      className={
                        index % 2 === 0 ? "bg-gray-800" : "bg-gray-750"
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {row.formattedDate}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {row.impressions.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {row.clicks.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {row.plays.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {row.ctr}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {row.ctp}%
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="bg-gray-900">
                  <tr>
                    <td className="px-6 py-3 text-sm font-medium text-gray-300">
                      Totals
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-300">
                      {processData
                        .reduce((sum, row) => sum + row.impressions, 0)
                        .toLocaleString()}
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-300">
                      {processData
                        .reduce((sum, row) => sum + row.clicks, 0)
                        .toLocaleString()}
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-300">
                      {processData
                        .reduce((sum, row) => sum + row.plays, 0)
                        .toLocaleString()}
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-300">
                      {(
                        processData.reduce(
                          (sum, row) => sum + parseFloat(row.ctr),
                          0
                        ) / processData.length
                      ).toFixed(2)}
                      %
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-300">
                      {(
                        processData.reduce(
                          (sum, row) => sum + parseFloat(row.ctp),
                          0
                        ) / processData.length
                      ).toFixed(2)}
                      %
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MetricsChart;
